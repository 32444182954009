<template>
    <div v-if="filters">
        <v-section-divider>
            General Filters
        </v-section-divider>

        <div v-if="filters" class="grid grid-cols-1 gap-4 p-default">
            <v-form-select
                v-if="organizations.length > 2"
                v-model="filters.organization_id"
                :options="selectOptions(organizations, 'name', 'organization_id')"
                default-label="All Organizations"
            />

            <v-form-select
                v-model="filters.job"
                :options="jobsOptions"
                default-label="All Jobs"
            />

            <v-form-select
                v-model="filters.order_line_status_id"
                :options="selectOptions(orderLineStatuses, 'name', 'order_line_status_id')"
                default-label="All Statuses"
            />

            <v-form-select
                v-model="filters.player_environment_id"
                :options="selectOptions(playerEnvironments, 'name', 'player_environment_id')"
                default-label="All Environments"
            />

            <v-form-select
                v-model="filters.has_fast_pass"
                :options="fastPassOptions"
                default-label="All Passes"
            />

            <v-form-select
                v-model="filters.product_id"
                :options="selectOptions(products, 'name', 'product_id')"
                default-label="All Products"
            />
        </div>

        <v-section-divider>
            Game Platforms
        </v-section-divider>

        <div class="grid grid-cols-1 gap-4 p-default">
            <template v-for="(gamePlatform, gamePlatformIndex) in gamePlatforms">
                <v-form-checkbox :key="'gamePlatform_' + gamePlatformIndex" v-model="filters.game_platform_ids" :value="gamePlatform.game_platform_id">
                    <span class="block">
                        {{ gamePlatform.name }}
                    </span>
                </v-form-checkbox>
            </template>
        </div>
    </div>
</template>

<script>
import TableFiltersMixin from "@/mixins/table-filters";
import GamePlatformService from "@/services/modules/game-platform-service";
import OrderLineStatusService from "@/services/modules/order-line-status-service";
import ProductService from "@/services/modules/product-service";
import PlayerEnvironmentService from "@/services/modules/player-environment-service";
import UserService from "@/services/modules/user-service";

export default {
    name: "JobTableFilters",
    mixins: [
        TableFiltersMixin,
    ],
    data() {
        return {
            /**
             * @todo Write a description.
             */
            filters: {
                /**
                 * @todo Write a description.
                 */
                job: this.$route.query.job ?? null,

                /**
                 * @todo Write a description.
                 */
                order_line_status_id: this.$route.query.order_line_status_id ?? null,

                /**
                 * @todo Write a description.
                 */
                product_id: this.$route.query.product_id ?? null,

                /**
                 * @todo Write a description.
                 */
                player_environment_id: this.$route.query.player_environment_id ?? null,

                /**
                 * @todo Write a description.
                 */
                has_fast_pass: this.$route.query.has_fast_pass ?? null,

                /**
                 * @todo Write a description.
                 */
                game_platform_ids: this.parseQueryString(this.$route.query.game_platform_ids) ?? [],

                /**
                 * @todo Write a description.
                 */
                organization_id: this.$route.query.organization_id ?? null,
            },

            /**
             * @todo Write a description.
             */
            jobsOptions: [
                {
                    label: "All Jobs",
                    value: null,
                },
                {
                    label: "Assigned to You",
                    value: "Assigned",
                },
                {
                    label: "Assisting",
                    value: "Assisting",
                },
            ],

            /**
             * @todo Write a description.
             */
            fastPassOptions: [
                {
                    label: "All Passes",
                    value: null,
                },
                {
                    label: "Has Fast Pass",
                    value: true,
                },
                {
                    label: "Has No Fast Pass",
                    value: false,
                },
            ],

            /**
             * @todo Write a description.
             */
            orderLineStatuses: [],

            /**
             * @todo Write a description.
             */
            gamePlatforms: [],

            /**
             * @todo Write a description.
             */
            products: [],

            /**
             * @todo Write a description.
             */
            playerEnvironments: [],

            /**
             * @todo Write a description.
             */
            organizations: [],
        };
    },
    created() {
        OrderLineStatusService.index()
            .then((response) => {
                this.orderLineStatuses = [
                    {
                        name: "All Statuses",
                        order_line_status_id: null,
                    },
                    ...response.data.data,
                ];
            });

        PlayerEnvironmentService.index()
            .then((response) => {
                this.playerEnvironments = [
                    {
                        name: "All Environments",
                        player_environment_id: null,
                    },
                    ...response.data.data,
                ];
            });

        UserService.getOrganizations(this.$me.getUser().user_id)
            .then((response) => {
                this.organizations = [
                    {
                        name: "All Organizations",
                        organization_id: null,
                    },
                    ...response.data.data,
                ];
            });

        GamePlatformService.index()
            .then((response) => {
                this.gamePlatforms = [
                    ...response.data.data,
                ];
            });

        this.fetchProductFilterOptions();
    },
    methods: {
        /**
         * @todo Write a description.
         */
        selectOptions(data, labelField, valueField) {
            if (data) {
                return data.map((item) => ({ label: item[labelField], value: item[valueField] }));
            }
            return null;
        },
        clearFilters() {
            this.filters = { game_platform_ids: [] };
        },
        fetchProductFilterOptions() {
            ProductService.index({ paginated: false })
                .then((response) => {
                    this.products = [
                        {
                            name: "All Products",
                            product_id: null,
                        },
                        ...response.data.data,
                    ];
                });
        },
    },
};
</script>
