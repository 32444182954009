<template>
    <v-main>
        <template v-slot:sidebar>
            <v-section-heading>
                <template v-slot:default>
                    Filters
                </template>

                <template v-slot:actions>
                    <div v-if="hasFilters">
                        <v-link color="primary" @click="clearFilters()">
                            Clear
                        </v-link>
                    </div>
                </template>
            </v-section-heading>

            <v-job-table-filters ref="jobTableFilters" v-model="filters" />
        </template>

        <template v-slot:default="{ toggleSidebar }">
            <v-section-heading>
                <template v-slot:default>
                    Jobs
                </template>

                <template v-slot:description>
                    A list of all the jobs in the application.
                </template>

                <template v-slot:actions>
                    <div class="block lg:hidden">
                        <v-button color="primary" @click.stop="toggleSidebar">
                            <i class="fa fa-filter mr-2" />
                            Filter Results
                        </v-button>
                    </div>
                </template>
            </v-section-heading>

            <v-job-table ref="jobTable" :query="filters" @selected-game-updated="refreshProductFilter" @is-loading-updated="onIsLoadingUpdated" />
        </template>
    </v-main>
</template>

<script>
import JobTable from "@/components/application/job/job-table.vue";
import JobTableFilters from "@/components/application/job/job-table-filters.vue";
import Main from "@/layouts/panel/main.vue";

export default {
    components: {
        "v-job-table": JobTable,
        "v-job-table-filters": JobTableFilters,
        "v-main": Main,
    },
    data() {
        return {
            /**
             * @todo Write a description.
             */
            filters: {},

            /**
             * @todo Write a description.
             */
            isTableLoading: true,
        };
    },
    computed: {
        hasFilters() {
            return this.parseFilters(this.filters).length;
        },
    },
    created() {
        // Filter jobs by the query string parameters on initial page load.
        this.filters = {
            ...this.$route.query,
            game_platform_ids: this.parseQueryString(this.$route.query.game_platform_ids),
        };
    },
    methods: {
        clearFilters() {
            this.$refs.jobTableFilters.clearFilters();
        },
        refreshProductFilter() {
            this.$refs.jobTableFilters.fetchProductFilterOptions();
        },
        onIsLoadingUpdated(value) {
            this.isTableLoading = value;
        },
        parseQueryString(str = null) {
            if (!str || !str.length) {
                return [];
            }

            if (Array.isArray(str)) {
                return str;
            }

            return [str];
        },
    },
};
</script>
